<template>
  <div class="auth-wrapper auth-v1 px-2 mx-auto col-md-8 register">
    <div class="auth-inner py-2">
      <form-wizard
        color="#4D25BC"
        ref="signaturewizard"
        :title="null"
        :subtitle="null"
        finish-button-text="Terminer"
        back-button-text="Précédent"
        next-button-text="Suivant"
        class="steps-transparent mb-3 justify-content-center"
        @on-complete="formSubmitted"
        :start-index="5"
      >
        <tab-content disabled title="Formule" icon="feather icon-package">
          
        </tab-content>
        <tab-content disabled title="Cabinet" icon="feather icon-file-text">
          
        </tab-content>
        <tab-content disabled title="Contact" icon="feather icon-user">
          
        </tab-content>
        <tab-content disabled title="Adhésion" icon="feather icon-mail">

        </tab-content>
        <tab-content disabled title="Paiement" icon="feather icon-credit-card">
          
        </tab-content> 
        <tab-content title="Signature" icon="feather icon-award" active>
          <h3 class="text-center mb-5">Confirmation</h3>
          <h5 class="card-text text-left mt-2 mb-2 text-capitalize">Bienvenue <span class="text-capitalize">{{prenom}}</span> <span class="text-uppercase">{{nom}}</span>,</h5>
          <p class="card-text text-left mb-2 ">Nous avons le plaisir de vous confirmer votre inscription auprès de la Plateforme du Courtage 👋</p>
          <p class="card-text text-left ">Votre identifiant vous a été transmis par mail à l'adresse <strong class="text-primary">{{user_email}}</strong>. N'oubliez pas de vérifier vos courriers indésirables si vous ne le trouvez pas.</p>
          <!-- <h4 class="text-center mb-2">Login :  <span class="font-weight-bolder"> {{user_email}}</span> </h4> -->
          <p class="card-text text-left ">Votre mot de passe est celui renseigné pendant le processus d'inscription. Vous pourrez le changer si nécessaire.</p>
          <p class="card-text text-left ">Profitez dès maintenant de toutes les fonctionnalités de la solution Bubbleln en cliquant sur le lien ci-dessous.</p>
          <p class="card-text text-left mt-2">Nous vous remercions pour votre confiance.</p>
          <p class="card-text text-left font-italic">L'équipe BubbleIn.</p>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
  BButton,
  BCardText,
  BListGroup,
  BListGroupItem,
  BCard,
  BBadge,
  BImg,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCard,
    BBadge,
    BImg,
  },
  data() {
    return {
      username:'',
      user_email: '',
      nom: '',
      prenom:''
    };
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    localize("fr");
    localStorage.setItem("new_user","new_user");
    let user = this.$route.params.id;
    this.$http.get(`userById/`+user)
            .then((r) => {
              this.username = r.data.username;
              this.user_email = r.data.email;
              this.nom = r.data.nom;
              this.prenom = r.data.prenom;

              //Analytics
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('event', 'Register', {
                'event_category': 'Register',
                'event_label': r.denomination_commercial,
                'value': 'MEMBRE'
              })
            })
            .catch((err) => {
              // console.log(err);
              this.$router.push({ name: 'auth-login' })
            });

  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
  methods: {
    formSubmitted(){
      this.$router.push({ name: "login", params: {'new_user': 'new_user'} });
    },
    // disabledLinks()
    // {
    //   var links = window.document.querySelectorAll('li a');
    //   console.log(links)
    //   for( let i = 0; i < links.length;i++) {
    //     console.log(links[i]);
    //   }
      
    // }
  },
  // beforeCreate() {
  //   console.log(this.$route.params.id)
  //   if (!this.$route.params.id) {
  //     this.$router.push({ name: "register" });
  //   }
  // },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.register .wizard-nav-pills {
  justify-content: center;
}
.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle.checked
{
  background-color: #ededed;
}
.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle.checked .wizard-icon
{
  color: #b8c2cc;
}
.wizard-footer-left button{
  display: none !important;
}
</style>
